import { graphql } from 'gatsby';
import React from 'react';
import App from './App/App';
import { ApolloProvider } from '@apollo/client';
import { client } from '../apollo/client';
import SEO from "./seo"

const ResultsPage = ({ data: { gv }, pageContext }) => (
    <ApolloProvider client={client}>
        <SEO context={pageContext} />
        <App btc={gv} context={pageContext} />
    </ApolloProvider>
)
export default ResultsPage

// Getting all usable content at build-time and passing it in 'btc'
export const query = graphql`
  query($makerIdSelected: String $cameraIdSelected: String $lensIdSelected: String) {
    gv {
      makes { _id Make }
      cameras(make: $makerIdSelected) { _id CameraModel CameraModelShort CameraModelAlts CameraModelFriendly Brand EffectivePixels AnnouncedDate SensorSize min_res max_res min_iso max_iso photo_count }
      lenses(make: $makerIdSelected, camera: $cameraIdSelected) { _id LensModel Brand AnnouncedDate Weight photo_count }
      camera(camera: $cameraIdSelected) { _id CameraModel CameraModelFriendly EffectivePixels Brand AnnouncedDate MSRP SensorSize IsoMin IsoMax Weight photo_count }
      lens(lens: $lensIdSelected) { _id LensModel LensModelFriendly Brand AnnouncedDate MSRP Weight photo_count }
    }
  }
`
